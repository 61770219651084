import i18n from '@/i18n';

const storeState = {
  locale: i18n.locale,
  urls: window.urls,
  apiErrors: {},
  isBannerShown: !!window.isBannerShown,
  isLegalEntityOrderDisabled: !!window.isLegalEntityOrderDisabled,
};

// actions
const actions = {};

// mutations
const mutations = {
  ADD_API_ERROR(state, error) {
    state.apiErrors = {
      ...state.apiErrors,
      [error.id]: i18n.t(`errors.${error.id}`, error.meta),
    };
  },
  REMOVE_API_ERRORS(state, keys) {
    keys.forEach((key) => {
      delete state.apiErrors[key];
    });
  },
  CLEAR_API_ERRORS(state) {
    state.apiErrors = {};
  },
};

export default {
  namespaced: true,
  state: storeState,
  actions,
  mutations,
};
