import { cloneDeep } from 'lodash-es';
import i18n from '@/i18n';

const product = {
  namespaced: true,
  state: () => ({
    id: null,
    serverId: null,
    productOptions: null,
    defaultLayers: [], // initial value of layers
    layers: [], // layers selected by user
    price: null,
    priceWithDiscount: null,
    isPriceLoading: false,
    url: undefined,
  }),
  getters: {
    productDataFormatted(state, getters, rootState) {
      const { currentRegion, currentRegionCode: code, currentRegionGeometry } = rootState.regions;
      if (state.productOptions && currentRegion && code) {
        const options = {
          ...state.productOptions,
          ...state.productOptions.formModel,
          layers: state.layers,
        };
        delete options.formModel;

        const { country, name } = currentRegion;
        const wkt = currentRegionGeometry && currentRegionGeometry.wkt;
        const region = { code, geometry: wkt, country, name };

        return {
          id: state.id,
          serverId: state.serverId,
          lang: i18n.locale,
          region,
          options,
          url: state.url,
        };
      }
      return null;
    },
    finalPrice(state) {
      return state.priceWithDiscount || state.price;
    },
  },
  mutations: {
    SET_PRODUCT_ID(state, id) {
      state.id = id;
    },
    SET_PRODUCT_SERVER_ID(state, serverId) {
      state.serverId = serverId;
    },
    SET_PRODUCT_OPTIONS(state, productOptions) {
      state.productOptions = cloneDeep(productOptions);
    },
    SET_PRODUCT_LAYERS(state, layers) {
      state.layers = layers;
    },
    SET_DEFAULT_LAYERS(state, layers) {
      state.defaultLayers = layers;
    },
    SET_PRICE(state, price) {
      state.price = price;
    },
    SET_PRICE_WITH_DISCOUNT(state, priceWithDiscount) {
      state.priceWithDiscount = priceWithDiscount;
    },
    SET_IS_PRICE_LOADING(state, isPriceLoading) {
      state.isPriceLoading = isPriceLoading;
    },
    SET_PRODUCT_URL(state, url) {
      state.url = url;
    },
  },
};

export default product;
