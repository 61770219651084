import axios from 'axios';
import { notice } from '@nextgis/vuetify-notice';
import i18n from '@/i18n';
import { convertAxiosErrorToAppError } from '@/utils/errorUtils';

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const pushNotice = (errorText) => {
  notice(errorText, {
    color: 'error',
    timeout: 5000,
    icon: 'error',
  });
};

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (!axios.isCancel(error)) {
    const { errorMessage, errorStatus, noErrorNotification } = convertAxiosErrorToAppError({
      axiosError: error,
      locale: i18n.locale,
    });

    if (
      !noErrorNotification &&
      (errorStatus === 400 || errorStatus === 404 || errorStatus >= 500) &&
      !axios.isCancel(error)
    )
      pushNotice(errorMessage);

    return Promise.reject(error);
    // }
    // const { config } = error;
    // console.warn(`${config.url} was cancelled`);
    // return Promise.resolve();
  },
);

export default httpClient;
