<template>
  <div :class="`banner `">
    <picture>
      <source
        media="(min-width: 800px)"
        type="image/webp"
        :srcset="`${$publicPath}img/banners/bf24_banner_2x_${bannerLocale}.webp`"
      />
      <source type="image/webp" :srcset="`${$publicPath}img/banners/bf24_banner_s_2x_${bannerLocale}.webp`" />
      <source
        media="(min-width: 800px)"
        type="image/png"
        :srcset="`${$publicPath}img/banners/bf24_banner_1x_${bannerLocale}.png 1x, ${$publicPath}img/banners/bf24_banner_2x_${bannerLocale}.png 2x`"
      />
      <source
        type="image/png"
        :srcset="`${$publicPath}img/banners/bf24_banner_s_1x_${bannerLocale}.png 1x, ${$publicPath}img/banners/bf24_banner_s_2x_${bannerLocale}.png 2x`"
      />
      <img
        :src="`${$publicPath}img/banners/bf24_banner_1x_${bannerLocale}.png`"
        alt="50% off for all datasets and subscriptions"
      />
    </picture>
  </div>
</template>

<script>
export default {
  name: 'TopBanner',
  data() {
    return {
      publicPath: process.env.VUE_APP_PUBLIC_PATH,
    };
  },
  computed: {
    bannerLocale() {
      return this.$i18n.locale === 'ru' ? 'ru' : 'en';
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  background-color: #1c1c1c;
  height: $top-banner-height;

  img {
    width: 100%;
    max-width: 2560px;
    height: 100%;
    object-fit: cover;
    display: block;
    margin: 0 auto;
  }
}
</style>
