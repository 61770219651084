<template>
  <BaseLayout
    v-bind="$attrs"
    :class="{
      'with-top-banner': isBannerShown,
    }"
  >
    <template v-slot:banner v-if="isBannerShown">
      <TopBanner />
    </template>
    <template v-slot:header-action>
      <region-search-autocomplete
        v-if="hasSearch"
        attach="#header-region-search-autocomplete"
        wrapper-id="header-region-search-autocomplete"
        class="header-region-search-autocomplete"
        v-model="regionSelectedInSearch"
        @input="goToRegion"
        @update:search-input="onUpdateSearchInput"
        @search="sendEventToCounters"
        :search-query="regionSearchQuery"
        :key="$route && $route.path"
        prepend-inner-icon="mdi-magnify"
        append-icon=""
        filled
        solo
        :outlined="false"
        flat
        clearable
        :placeholder="$t('app.searchLocation')"
      />
    </template>
    <slot></slot>
  </BaseLayout>
</template>

<script>
import { mapState } from 'vuex';
import { goToProductFromSearch } from '@/services/searchService';
import BaseLayout from '@ngservices_front/layouts/BaseLayout.vue';
import RegionSearchAutocomplete from '@/components/app/RegionSearchAutocomplete.vue';
import TopBanner from '@/components/app/TopBanner.vue';

export default {
  components: { BaseLayout, RegionSearchAutocomplete, TopBanner },
  props: {
    hasSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      regionSelectedInSearch: undefined,
      regionSearchQuery: '',
    };
  },
  computed: {
    ...mapState('app', ['isBannerShown']),
  },
  methods: {
    onUpdateSearchInput(newQuery) {
      this.regionSearchQuery = newQuery;
    },
    goToRegion() {
      if (this.regionSelectedInSearch) {
        goToProductFromSearch({
          regionFromSearch: this.regionSelectedInSearch,
          route: this.$route,
          router: this.$router,
        });

        this.$nextTick(() => {
          this.regionSelectedInSearch = undefined;
          this.regionSearchQuery = '';
        });
      }
    },
    sendEventToCounters(query) {
      if (window.ym) window.ym(42050389, 'reachGoal', 'app-search', { query });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-region-search-autocomplete {
  width: 200px;
  white-space: normal;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    width: 300px;
  }
}

.with-top-banner {
  :deep(.app-sidebar.v-navigation-drawer) {
    top: $header-height + $top-banner-height !important;
  }
}
</style>
