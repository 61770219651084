import { getUrlByLocale } from '@ngservices_front/services/UrlService';
import SplitTest from '@/utils/SplitTest';

const splitTest = new SplitTest();
if (window.ym) window.ym(42050389, 'params', { params: { test_case: splitTest.case } });

export const SPLIT_TEST_CASE = SplitTest.getSavedCase();

export const HOST = 'https://data.nextgis.com/';

export const LOCALIZATION = {
  routeParam: 'lang',
  localesShort: ['en', 'es', 'fr', 'ru'],
  locales: [
    {
      title: 'English',
      value: 'en',
    },
    {
      title: 'Français',
      value: 'fr',
    },
    {
      title: 'Español',
      value: 'es',
    },
    {
      title: 'Русский',
      value: 'ru',
    },
  ],
};

const getTopMenuItems = ({ i18n }) => [
  {
    id: 'catalog',
    text: i18n.t('nav.catalog'),
    to: { name: 'catalog_level', params: { lang: i18n.locale, level: 'subdivisions' } },
  },
  {
    id: 'about',
    text: i18n.t('nav.about'),
    to: { name: 'about', params: { lang: i18n.locale } },
  },
  {
    id: 'howto',
    text: i18n.t('nav.howto'),
    to: { name: 'howto', params: { lang: i18n.locale } },
  },
  {
    id: 'faq',
    text: i18n.t('nav.faq'),
    to: { name: 'faq', params: { lang: i18n.locale } },
  },
];

export const CACHED_COUNTRIES = [
  'RU',
  'US',
  'ES',
  'BY',
  'UA',
  'KZ',
  'SA',
  'CN',
  'IN',
  'UZ',
  'GR',
  'RO',
  'MY',
  'JP',
  'IT',
  'DE',
  'AE',
  'MX',
  'VN',
  'AZ',
  'NG',
  'KR',
  'IM',
];

const config = ({ i18n }) => {
  const nextgisSiteUrl = getUrlByLocale('nextgis_site', i18n ? i18n.locale : 'en');

  return {
    serviceName: 'Data',
    email: 'data@nextgis.com',
    withAuthorization: false,
    topMenuItems: getTopMenuItems({ i18n }),
    sidebarMenuItems: getTopMenuItems({ i18n }),
    bottomMenuItems: [
      {
        text: i18n.t('nav.about'),
        link: `/${i18n.locale}/about/`,
      },
      {
        text: i18n.t('nav.terms'),
        link: `${nextgisSiteUrl}/terms-data/`,
        target: '_blank',
      },
      {
        text: i18n.t('nav.privacy'),
        link: `${nextgisSiteUrl}/privacy/`,
        target: '_blank',
      },
    ],
    footerView: 'large',
    isSPA: true, // use <router-link> instead <a>
  };
};

export default config;
